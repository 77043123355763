/**
 * 存数据
 */
export const setItem = (key, value) => {
  if (typeof value == 'object') {
    //复杂类型需要转换
    value = JSON.stringify(value);
  }
  window.localStorage.setItem(key, value);
};
/**
 * 取数据
 */
export const getItem = (key) => {
  const data = window.localStorage.getItem(key);
  try {
    return JSON.parse(data);
  } catch {
    //如果json解析失败，就是普通类型数据，直接返回
    return data;
  }
};
/**
 * 删除指定数据
 */

export const removeItem = (key) => {
  window.localStorage.removeItem(key);
};
/**
 * 删除所有数据
 */
export const removeAllItem = () => {
  window.localStorage.clear();
};
