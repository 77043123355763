import layout from '@/layout';

export default {
  path: '/sys',
  component: layout,
  redirect: '/sys/store-auth',
  name: 'articleRanking',
  meta: { title: '系统设置', icon: 'chart' },
  children: [
    {
      path: '/sys/store-auth',
      component: () => import('@/views/6300sys/store-auth/index'),
      meta: {
        title: '店铺授权',
        icon: 'permission',
      },
    },
    // {
    //   path: '/article/:id',
    //   component: () => import('@/views/article-detail/index'),
    //   meta: {
    //     title: 'articleDetail',
    //   },
    // },
  ],
};
