<template>
  <el-breadcrumb class="breadcrumb" separetor="/">
    <!-- vue3的动画用法 定义name, 在transition.scss中按格式写css, 以该name开头来写 如 .breadcrumb-enter-active-->
    <transition-group name="breadcrumb">
      <el-breadcrumb-item
        v-for="(item, index) in breadcrumbData"
        :key="item.path"
      >
        <!-- 不可点击项 -->
        <span class="no-redirect" v-if="index === breadcrumbData.length - 1">{{
          item.meta.title
        }}</span>
        <!-- 可点击项 -->
        <span class="redirect" v-else @click="onLinkClick(item)">{{
          item.meta.title
        }}</span>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

//方法： 生成面包屑数据
const breadcrumbData = ref([]);
const getBreadcrumbData = () => {
  //route.matched 返回的是当前路由的路由表，包括上级
  breadcrumbData.value = route.matched.filter(
    (item) => item.meta && item.meta.title
  );
};

//监听路由变化
const route = useRoute();
watch(
  route,
  () => {
    getBreadcrumbData();
  },
  { immediate: true }
);

const router = useRouter();
const onLinkClick = (item) => {
  router.push(item.path);
};

//将来主题替换， hover 的颜色设置为主色
const store = useStore();
const linklHoverColor = ref(store.getters.cssVar.menuBg);
</script>

<style lang="scss" scoped>
.breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;
  .redirect {
    color: #666;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      color: v-bind(linklHoverColor);
    }
  }

  :deep(.no-redirect) {
    color: #97a8be;
    cursor: text;
  }
}
</style>
