<template>
  <ul class="context-menu-container">
    <li @click="onRefreshClick">刷新</li>
    <li @click="closeLeftTags">关闭左侧</li>
    <li @click="closeRightTags">关闭右侧</li>
    <li @click="closeOtherTags">关闭其它</li>
  </ul>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
});

const router = useRouter();
const store = useStore();
//刷新
const onRefreshClick = () => {
  router.go(0);
};
//关闭左侧
const closeLeftTags = () => {
  store.commit('app/removeTagsView', {
    type: 'left',
    index: props.index,
  });
};
//关闭右侧
const closeRightTags = () => {
  store.commit('app/removeTagsView', {
    type: 'right',
    index: props.index,
  });
};
//关闭其他
const closeOtherTags = () => {
  store.commit('app/removeTagsView', {
    type: 'other',
    index: props.index,
  });
};
</script>

<style lang="scss" scoped>
.context-menu-container {
  position: fixed;
  background: #fff;
  z-index: 3000;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
  li {
    margin: 0;
    padding: 7px 16px;
    cursor: pointer;
    &:hover {
      background: #eee;
    }
  }
}
</style>
