import { createRouter, createWebHashHistory } from 'vue-router';
import layout from '@/layout/index';
import sysRoutes from './modules/6300sys';

/**
 * 私有路由表
 */
const privateRoutes = [
  sysRoutes,
  {
    path: '/user',
    component: layout,
    redirect: '/user/manage',
    meta: {
      title: '用户中心',
      icon: 'personnel',
    },
    children: [
      {
        path: '/user/manage',
        component: () => import('@/views/user-manage/index'),
        meta: {
          title: '用户管理',
          icon: 'personnel-manage',
        },
      },
      {
        path: '/user/role',
        component: () => import('@/views/role-list/index'),
        meta: {
          title: '角色列表',
          icon: 'role',
        },
      },
      {
        path: '/user/permission',
        component: () => import('@/views/permission-list/index'),
        meta: {
          title: '权限列表',
          icon: 'permission',
        },
      },
      {
        path: '/user/info/:id',
        name: 'userInfo',
        component: () => import('@/views/user-info/index'),
        meta: {
          title: '用户信息',
        },
      },
      {
        path: '/user/import',
        name: 'import',
        component: () => import('@/views/import/index'),
        meta: {
          title: '导入Excel',
        },
      },
    ],
  },
  {
    path: '/article',
    component: layout,
    redirect: '/article/ranking',
    meta: {
      title: '文章管理',
      icon: 'article',
    },
    children: [
      {
        path: '/article/ranking',
        component: () => import('@/views/article-ranking/index'),
        meta: {
          title: '文章排名',
          icon: 'article-ranking',
        },
      },
      {
        path: '/article/:id',
        component: () => import('@/views/article-detail/index'),
        meta: {
          title: '文章详情',
        },
      },
      {
        path: '/article/create',
        component: () => import('@/views/article-create/index'),
        meta: {
          title: '创建文章',
          icon: 'article-create',
        },
      },
      {
        path: '/article/editor/:id',
        component: () => import('@/views/article-create/index'),
        meta: {
          title: '文章编辑',
        },
      },
    ],
  },
];

/**
 * 公开路由表
 */
const publicRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
  },
  {
    path: '/',
    component: layout,
    redirect: '/sys/store-auth',
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index/index'),
        meta: {
          title: '首页',
          icon: 'logo',
        },
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/profile/index'),
        meta: {
          title: '个人中心',
          icon: 'user',
        },
      },
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/error-page/404'),
      },
      {
        path: '/401',
        name: '401',
        component: () => import('@/views/error-page/401'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: [...publicRoutes, ...privateRoutes],
});

// 导航守卫，更新页面标题
router.beforeEach((to) => {
  document.title = to.meta.title || '拉森科技';
});

/**
 * 动态菜单显示规则
 * 对于单个路由规则而言（循环）：
如果meta && meta.title && meta.icon则显示在 menu 菜单中， 否则：不显示在 menu 菜单中
	其中 title 为显示的内容，icon 为显示的图标
	1. 如果存在 children ：则以 el-sub-menu（子菜单） 展示
	2. 否则：则以 el-menu-item（菜单项） 展示
 */

export default router;
