// 此处不要导入 @/i18n 使用 i18n.global ，因为我们在 router 中 layout 不是按需加载，所以会在 Guide 会在 I18n 初始化完成之前被直接调用。导致 i18n 为 undefined
const steps = () => {
  return [
    {
      element: '#guide-start',
      popover: {
        title: '向导',
        description: '开始使用功能向导',
        position: 'bottom-right',
      },
    },
    {
      element: '#guide-hamburger',
      popover: {
        title: '收缩菜单',
        description: '点击可以收缩或展开菜单',
      },
    },
    {
      element: '#guide-breadcrumb',
      popover: {
        title: '导航栏',
        description: '右键点击卡片可关闭多个',
      },
    },
    // {
    // 	element: '#guide-search',
    // 	popover: {
    // 		title: '菜单搜索',
    // 		description: '快速搜索功能菜单',
    // 		position: 'bottom-right'
    // 	}
    // },
    {
      element: '#guide-full',
      popover: {
        title: '全屏展示',
        description: '隐藏浏览器地址栏全屏展示',
        position: 'bottom-right',
      },
    },
    // {
    // 	element: '#guide-theme',
    // 	popover: {
    // 		title: '主题切换',
    // 		description: '点击切换主题',
    // 		position: 'bottom-right'
    // 	}
    // },
    {
      element: '#guide-tags',
      popover: {
        title: '导航栏',
        description: '右键点击卡片可关闭多个',
      },
    },
    {
      element: '#guide-sidebar',
      popover: {
        title: '侧边栏',
        description: '当前用户可见的菜单列表',
        position: 'right-center',
      },
    },
  ];
};
export default steps;
