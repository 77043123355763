export const TOKEN = 'token';
//token时间戳
export const TOKEN_TIME_STAMP = 'timestamp';
//超时时长
const ONE_DAY = 24 * 3600 * 1000;
export const TOKEN_TIMEOUT_VALUE = 30 * ONE_DAY;
//国际化语言
export const LANG = 'language';
//tags
export const TAGS_VIEW = 'tagsView';
