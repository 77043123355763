<template>
  <div class="">
    <el-tooltip content="功能引导">
      <svg-icon icon="guide" id="guide-start" @click="onClick"></svg-icon>
    </el-tooltip>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import steps from './steps';

const driverObj = driver({
  showProgress: true,
  allowClose: true, //是否允许点击蒙版来关闭
  closeBtnText: '关闭',
  nextBtnText: '下一个',
  prevBtnText: '上一个',
  doneBtnText: '结束',
  steps: steps(),
});

const onClick = () => {
  driverObj.drive();
};
</script>

<style lang="scss" scoped></style>
