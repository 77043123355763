import { TOKEN_TIME_STAMP, TOKEN_TIMEOUT_VALUE } from '@/constant';
import { getItem, setItem } from './storage';

/**
 * 获取时间戳
 */
export function getTokenTimeStamp() {
  return getItem(TOKEN_TIME_STAMP);
}

/**
 * 设置时间戳
 */
export function setTokenTimeStamp() {
  return setItem(TOKEN_TIME_STAMP, Date.now());
}

/**
 * 是否超时
 */
export function isTokenTimeout() {
  const currentTime = Date.now();
  const timeStamp = getTokenTimeStamp();
  return currentTime - timeStamp > TOKEN_TIMEOUT_VALUE;
}
