import router from './router';
import store from './store';

//白名单
const whiteList = ['/login'];

/**
 * 路由的前置守卫
 * @param to 要打哪里去
 * @param from 从哪里来
 * @param next 是否要去
 */
router.beforeEach(async (to, from, next) => {
  //1.已登录，不允许去login页
  //2.未登录，只允许去login页
  if (store.getters.token) {
    //已登录，不允许访问login
    if (to.path === '/login') {
      next('/');
    } else {
      //已经登录了并向访问其他页面，判断用户资料是否存在
      //如果不存在
      if (!store.getters.hasUserInfo) {
        await store.dispatch('user/getUserInfo');
      }
      next();
    }
  } else {
    //用户未登录. 只允许去login页
    if (whiteList.indexOf(to.path) > -1) {
      next();
    } else {
      next('/login');
    }
  }
});
