/**
 * 全局属性配置:
 * 指定一些数据处理函数，在全局可调用
 * 如： $filters.dateFilter(var)
 *
 */

import dayjs from 'dayjs';

const dateFilter = (val, format = 'YYYY-MM-DD') => {
  if (!isNaN(val)) {
    //是数字的话
    val = parseInt(val);
  }
  return dayjs(val).format(format);
};
export default (app) => {
  app.config.globalProperties.$filters = {
    dateFilter,
  };
};
