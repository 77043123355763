import { getUserInfo, login } from '@/api/sys-passport';
import { TOKEN } from '@/constant';
import router from '@/router';
import { setTokenTimeStamp } from '@/utils/auth';
import { getItem, removeAllItem, setItem } from '@/utils/storage';

/**
 * vuex的常规写法
 * 理解	this.commit('user/setToken', '')
 * 其他页面调佣使用时：
 * import { useStore } from 'vuex'
 *	const store = useStore()
 *  store.dispatch('user/logout')
 */

export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN) || '',
    userInfo: {},
  }),
  mutations: {
    setToken(state, token) {
      state.token = token;
      setItem(TOKEN, token);
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
  },
  actions: {
    /**
     *登录
     */
    login({ commit }, userInfo) {
      const { username, password } = userInfo;
      return new Promise((resolve, reject) => {
        login({
          username,
          password,
        })
          .then((data) => {
            //utils/request中配置了响应拦截器，把响应已经提取出来了
            //触发mutations
            commit('setToken', data.token);
            router.push('/');
            //保存登录时间，用于设置token过期
            setTokenTimeStamp();
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * 获取用户信息
     */
    async getUserInfo({ commit }) {
      const res = await getUserInfo();
      commit('setUserInfo', res);
      return res;
    },
    /**
     * 退出登录
     */
    logout({ commit }) {
      commit('setToken', '');
      commit('setUserInfo', {});
      removeAllItem();
      //TODO : 清理权限相关设置
      router.push('/login');
    },
  },
};
