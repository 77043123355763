import variables from '@/styles/variables.module.scss';
//getters是什么鬼？
//这是启动一个快捷访问的方法封装
const getters = {
  token: (state) => state.user.token,
  /**
   *
   * @param {*} state
   * @returns true 表示用户信息存在
   */
  hasUserInfo: (state) => {
    return JSON.stringify(state.user.userInfo) !== '{}';
  },
  userInfo: (state) => state.user.userInfo,
  cssVar: () => variables,
  sidebarOpened: (state) => state.app.sidebarOpened,
  language: (state) => state.app.language,
  tagsViewList: (state) => state.app.tagsViewList,
};

export default getters;
